import React, {useContext} from 'react';
import {makeStyles} from "@material-ui/styles";
import {ThemeContext} from "../context/ThemeContext";

export const LEFT = 1;
export const RIGHT = 2;

function SideImage(props) {

	const {theme, setTheme} = useContext(ThemeContext)

	const useStyle = makeStyles({
		mainContainer: {
			position: "relative",
			"&::after":{
				position: "absolute",
				content: theme => `url('/pictures/${theme.picName}')`,
				left: props.side === LEFT ? 0 : "unset",
				right: props.side === RIGHT ? 0 : "unset",
				top: 0,
				filter: theme.name === "dark" ? "invert(1)" : "unset",
				zIndex: -1
			}
		},

		"@media screen and (max-width: 600px)": {
			mainContainer: {
				"&::after": {
					display: "none"
				}
			}
		}

	})

	const classes = useStyle({...props, theme: theme})

	return (
		<div className={classes.mainContainer}>
			{props.children}
		</div>
	);
}

export default SideImage;
