import React, {useContext, useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {ThemeContext} from "../../context/ThemeContext";
import {LangContext} from "../../context/LangContext";
import {Button, Input, TextArea} from "../../StyledComponents";
import Loading from "../../component/Loading";
import Select from "react-select";
import ingredient from "../../component/Ingredient";
import {NotificationManager} from "react-notifications";
import {servicePath} from "../../constants/Values";
import {useHistory, useLocation} from "react-router-dom";
import Creatable, {useCreatable} from 'react-select/creatable';
import CreatableSelect from "react-select/creatable";
import ReactStars from "react-rating-stars-component";

function SubmitRecipe(props) {

	const location = useLocation();

	console.log(location.state);

	const {theme} = useContext(ThemeContext);
	const {lang} = useContext(LangContext);

	const text =
		lang === "ar" ?
			{
				submitRecipe: "قدم وصفة",
				recipeNameAr: "*اسم الوصفه عربي",
				recipeNameEn: "*اسم الوصفه انجليزي",

				recipeDescriptionAr: "*وصف الوصفه عربي",
				recipeDescriptionEn: "*وصف الوصفه انجليزي",

				diff: "*الصعوبه",

				diffEasy: "سهل",
				diffMedium: "متوسط",
				diffHard: "صعب",

				prepTime: "*وقت التحضير بالدقائق",

				ingredients: "*المكونات",

				stepsEn: "*خطوات، انجليزية",
				stepsAr: "*خطوات، عربي",

				notesEn: "الملاحظات انجليزية",
				notesAR: "الملاحظات عربي",

				send: "تم",

				arabic: "عربي فقط",
				english: "انجليرية فقط",
				enAndAr: "عربي و انجليزي",

				emptyOrWrong: "واحد أو أكثر من الحقول المطلوبة فارغ أو به بيانات خاطئة",

				categories: "الفئات، حدد 0 أو أكثر",

				add: "ضيف",

				step: "خطوة",

				ingredient: "مكون",

				unit: "المقياس",

				amount: "الكميه",

				submit: "قدم",

				addUnit: "ضيف وحده قياس",

				addIngredient: "ضيف مكون",

				nameEn: "الأسم انجليزي",

				nameAr: "الأسم بالعربي",
			}
			:
			{
				submitForm: "Submit Recipe Form",
				recipeNameEn: "Recipe name English*",
				recipeNameAr: "Recipe name Arabic*",

				recipeDescriptionAr: "Recipe description Arabic*",
				recipeDescriptionEn: "Recipe description English*",

				diff: "difficulty*",

				diffEasy: "Easy",
				diffMedium: "Medium",
				diffHard: "Hard",

				prepTime: "Preparation time in minutes*",

				ingredients: "Ingredients*",

				stepsEn: "Steps, English*",
				stepsAr: "Steps, Arabic*",

				notesEn: "Notes English",
				notesAR: "Notes Arabic",

				send: "Done",
				arabic: "Arabic",
				english: "English",
				enAndAr: "English & Arabic",

				emptyOrWrong: "One or more of the required fields is empty or has wrong data",

				categories: "Categories, Select 0 or more",

				add: "Add",

				step: "Step",

				ingredient: "Ingredient",

				unit: "Unit",

				amount: "Amount",

				submit: "Submit",

				addUnit: "Add Measuring Unit",

				addIngredient: "Add Ingredient",

				nameEn: "Name English",

				nameAr: "Name Arabic",
			};

	const [isLoading, setIsLoading] = useState(false);

	const [recipePic, setRecipePic] = useState(location.state?.updating ? location.state.recipe.image : "");

	const [recipeLang, setRecipeLang] = useState(location.state?.updating ? parseInt(location.state.recipe.lang) : localStorage.getItem("lang") == 1 ? 1 : 2);

	const [recipeNameEn, setRecipeNameEn] = useState(location.state?.updating ? location.state.recipe.name_en : "");
	const [recipeNameAr, setRecipeNameAr] = useState(location.state?.updating ? location.state.recipe.name_ar : "");
	const [recipeDescriptionEn, setRecipeDescriptionEn] = useState(location.state?.updating ? location.state.recipe.description_en : "");
	const [recipeDescriptionAr, setRecipeDescriptionAr] = useState(location.state?.updating ? location.state.recipe.description_ar : "");

	const [notesEn, setNotesEn] = useState(location.state?.updating ? location.state.recipe.notes_en : "");
	const [notesAr, setNotesAr] = useState(location.state?.updating ? location.state.recipe.notes_ar : "");

	const [difficulty, setDifficulty] = useState(location.state?.updating ? [{
		value: '1',
		label: text.diffEasy,
	}, {value: '2', label: text.diffMedium}, {
		value: '3',
		label: text.diffHard,
	}][location.state.recipe.difficulty - 1] : "");
	const [preparationTime, setPreparationTime] = useState(location.state?.updating ? location.state.recipe.prepTime : "");

	const [ingredients, setIngredients] = useState([]);
	const [units, setUnits] = useState([]);

	const [categories, setCategories] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);

	const [ingredientsValues, setIngredientsValues] = useState({
		val: [{
			amount: '',
			unitId: "",
			ingredientId: "",
		}, {
			amount: '',
			unitId: "",
			ingredientId: "",
		}],
	});

	const [stepsEnValues, setStepsEnValues] = useState({
		val: location.state?.updating ? location.state.recipe.steps.filter((x) => x.lang == 2).reduce((acc, curr) => {
			acc.push(curr.name);
			return acc;
		}, []) : ['', ''],
	});
	const [stepsArValues, setStepsArValues] = useState({
		val: location.state?.updating ? location.state.recipe.steps.filter((x) => x.lang == 1).reduce((acc, curr) => {
			acc.push(curr.name);
			return acc;
		}, []) : ['', ''],
	});

	const [createNewIngredient, setCreateNewIngredient] = useState(false);

	const [nameEn, setNameEn] = useState("");
	const [nameAr, setNameAr] = useState("");

	const [typeToAdd, setTypeToAdd] = useState(0);

	const clickOutSideRef = useRef(null);


	const history = useHistory();

	const useStyle = makeStyles({

		mainContainer: {},

		expandable: {
			display: "flex",
			flexDirection: "column",

			"& > button": {
				margin: 0,
			},

			"& > div": {
				display: "flex",
				marginBottom: 4,
				marginTop: 4,
				alignItems: "center",

				"& > input": {
					width: "100%",
					marginRight: 4,
					padding: 12,
				},

				"& > div": {
					width: "100%",
					marginRight: 4,
				},
			},

		},

		addNewIngredient: {
			position: "fixed",
			width: "100%",
			height: "100%",
			padding: 24,
			top: 0,
			left: 0,
			background: "#000000ae",
			zIndex: 9999,
			alignItems: "center",
			justifyContent: "center",

			"& > div": {
				width: 300,
				padding: 24,
				background: theme => theme.backgroundColor,

				"& h2": {
					padding: 0,
					margin: 0,
				},

				"& button": {
					width: 265,
				},

			},
		},

		form: {
			display: "flex",
			flexDirection: "column",

			alignItems: "center",

			background: theme => theme.elevation4,
			padding: 24,

			borderRadius: 12,

			"& img": {
				height: "50vh",
				maxWidth: "100%",
				objectFit: "contain",
				border: "1px solid grey",

				marginBottom: 16,

			},

		},

		Inner: {
			display: "grid",
			width: "100%",
			maxWidth: 1000,
			// gridTemplateColumns: "repeat(auto-fit, minmax(45%, auto))",
			gridAutoFlow: "row dense",
			gap: 12,
			marginBottom: 24,

			"& > input": {
				boxSizing: "border-box",
				height: 45,
				padding: 12,
			},

			"& > textarea": {
				height: "9rem",
				boxSizing: "border-box",
				padding: 12,
				resize: "vertical",
			},

			"& > button": {
				margin: 0,
			},
		},

		langSelect: {
			marginBottom: 20,
		},

		modalInputContainer: {
			display: "flex",
			flexDirection: "column",
			gap: 4,

			"& > input": {
				background: theme => theme.name == "dark" ? "rgb(8,8,8)" : "white",
			},
		},

		"@media screen and (max-width: 1200px)": {
			Inner: {
				display: "flex",
				flexDirection: "column",
			},
		},

	});


	function validateSteps() {
		if (recipeLang == 1 || recipeLang == 0) {
			if (stepsArValues.val.length < 1) {
				return false;
			}

			return stepsArValues.val.some((step) => {
				return step.length < 1;
			});

		}

		if (recipeLang == 2 || recipeLang == 0) {
			if (stepsEnValues.val.length < 1) {
				return false;
			}

			return stepsEnValues.val.some((step) => {
				return step.length < 1;
			});
		}


		return true;
	}

	function validateIngredients() {
		if (ingredientsValues.val.length < 1) {
			return false;
		}

		return (ingredientsValues.val.some((ing) => {
			return (ing.amount.length < 1 && ing.unitId.length < 1 && ing.ingredientId.length < 1);
		}));
	}

	function handleSend() {

		console.log("got here");

		if (validateIngredients()) {
			console.log("got here 2");
			NotificationManager.error(text.emptyOrWrong, '', 5000, null, null, '');
			return;
		} else if (validateSteps()) {
			console.log("got here 3");
			NotificationManager.error(text.emptyOrWrong, '', 5000, null, null, '');
			return;
		} else if (!difficulty || !preparationTime || !recipePic) {
			console.log("got here 4");
			NotificationManager.error(text.emptyOrWrong, '', 5000, null, null, '');
			return;
		}

		if (recipeLang == 1 || recipeLang == 0) {
			console.log("got here 5");
			if (!recipeNameAr || !recipeDescriptionAr) {
				NotificationManager.error(text.emptyOrWrong, '', 5000, null, null, '');
				return;
			}
		}

		if (recipeLang == 2 || recipeLang == 0) {
			console.log("got here 6");
			if (!recipeNameEn || !recipeDescriptionEn) {
				NotificationManager.error(text.emptyOrWrong, '', 5000, null, null, '');
				return;
			}
		}

		location.state?.updating ? updateRecipe() : sendRecipe();

	}

	function sendRecipe() {


		let jwt = localStorage.getItem('token');

		if (jwt) {

			setIsLoading(true);

			const data = new FormData();
			//authentication
			data.append("jwt", jwt);
			data.append("lang", recipeLang);

			data.append("image", recipePic, recipePic.name);

			data.append("difficulty", difficulty.value);
			data.append("prepTime", preparationTime);

			data.append("categories", JSON.stringify(selectedCategories.reduce((acc, curr) => {
				acc.push(curr.value);
				return acc;
			}, [])));

			data.append("ingredients", JSON.stringify(ingredientsValues.val.reduce((acc, curr) => {
				acc.push({"ingredientId": curr.ingredientId, "unitId": curr.unitId, "amount": curr.amount});

				return acc;
			}, [])));

			if (recipeLang == 0 || recipeLang == 1) {
				data.append("nameAr", recipeNameAr);
				data.append("descriptionAr", recipeDescriptionAr);
				data.append("notesAr", notesAr);

				data.append("recipe_stepsAr", JSON.stringify(stepsArValues.val.reduce((acc, curr, index) => {
					acc.push({"name": curr, "step_order": index + 1});

					return acc;
				}, [])));

			}

			if (recipeLang == 0 || recipeLang == 2) {
				data.append("nameEn", recipeNameEn);
				data.append("descriptionEn", recipeDescriptionEn);
				data.append("notesEn", notesEn);

				data.append("recipe_stepsEn", JSON.stringify(stepsEnValues.val.reduce((acc, curr, index) => {
					acc.push({"name": curr, "step_order": index + 1});

					return acc;
				}, [])));

			}

			fetch(`${servicePath}add-recipe`, {
				method: 'POST',
				body: data,
				headers:
					{
						"Accept": "application/json",
					},
			}).then(function (res) {
				return res.json();
			}).then(function (data) {
				if (data == "1") {
					setIsLoading(false);
					history.push("/profile");
				} else if (data == "2") {
					NotificationManager.error("", 'مكون بنفس الاسم موجود بالفعل', 5000, null, null, '');
					setIsLoading(false);
				} else {
					setIsLoading(false);
				}
			}).catch(function (error) {
				setIsLoading(false);
				NotificationManager.error("حدث خطاء في الإتصال تأكد من اتصالك بالأنترنيت", 'خظاء في الإتصال', 5000, null, null, '');
			});

		}


	}

	function updateRecipe() {

		let jwt = localStorage.getItem('token');

		if (jwt) {

			setIsLoading(true);

			const data = new FormData();
			//authentication
			data.append("jwt", jwt);
			data.append("lang", recipeLang);

			data.append("id", location.state.recipe.id);

			typeof recipePic === "string" ? data.append("image", "0") : data.append("image", recipePic, recipePic.name);

			data.append("difficulty", difficulty.value);
			data.append("prepTime", preparationTime);

			data.append("categories", JSON.stringify(selectedCategories.reduce((acc, curr) => {
				acc.push(curr.value);
				return acc;
			}, [])));

			data.append("ingredients", JSON.stringify(ingredientsValues.val.reduce((acc, curr) => {
				acc.push({"ingredientId": curr.ingredientId, "unitId": curr.unitId, "amount": curr.amount});

				return acc;
			}, [])));

			if (recipeLang == 0 || recipeLang == 1) {
				data.append("nameAr", recipeNameAr);
				data.append("descriptionAr", recipeDescriptionAr);
				data.append("notesAr", notesAr);

				data.append("recipe_stepsAr", JSON.stringify(stepsArValues.val.reduce((acc, curr, index) => {
					acc.push({"name": curr, "step_order": index + 1});

					return acc;
				}, [])));

			}

			if (recipeLang == 0 || recipeLang == 2) {
				data.append("nameEn", recipeNameEn);
				data.append("descriptionEn", recipeDescriptionEn);
				data.append("notesEn", notesEn);

				data.append("recipe_stepsEn", JSON.stringify(stepsEnValues.val.reduce((acc, curr, index) => {
					acc.push({"name": curr, "step_order": index + 1});

					return acc;
				}, [])));

			}

			fetch(`${servicePath}update-recipe`, {
				method: 'POST',
				body: data,
				headers:
					{
						"Accept": "application/json",
					},
			}).then(function (res) {
				return res.json();
			}).then(function (data) {
				if (data == "1") {
					setIsLoading(false);
					history.push("/profile");
				} else if (data == "2") {
					NotificationManager.error("", 'مكون بنفس الاسم موجود بالفعل', 5000, null, null, '');
					setIsLoading(false);
				} else {
					setIsLoading(false);
				}
			}).catch(function (error) {
				setIsLoading(false);
				NotificationManager.error("حدث خطاء في الإتصال تأكد من اتصالك بالأنترنيت", 'خظاء في الإتصال', 5000, null, null, '');
			});

		}


	}

	function getIngredients() {
		fetch(`${servicePath}get-ingredients`)
			.then(response => response.json())
			.then(lowerIngredients => {
				setIngredients(lowerIngredients);
				if (location.state) {
					if (location.state.updating = true) {
						setIngredientsValues({
							val: location.state.recipe.ingredients.reduce((acc, curr) => {
								acc.push({"unitId": curr.unit_id, "ingredientId": curr.id, "amount": curr.quantity});
								return acc;
							}, []),
						});
					}
				}
			});
	}

	function getUnits() {
		fetch(`${servicePath}get-units`)
			.then(response => response.json())
			.then(units => setUnits(units));
	}

	function getCategories() {
		fetch(`${servicePath}get-categories`)
			.then(response => response.json())
			.then(categoriez => {
				setCategories(categoriez);
				if (location.state) {
					location.state.recipe.categories && setSelectedCategories(location.state.recipe.categories.reduce((acc, curr) => {
						acc.push({label: lang === "ar" ? curr.name_ar : curr.name_en, value: curr.id});
						return acc;
					}, []));
				}
			});
	}

	useEffect(() => {
		getCategories();
		getIngredients();
		getUnits();
	}, []);

	const classes = useStyle(theme);

	const customStyles = {
		menu: (provided, state) => ({
			...provided,
			padding: 4,
			backgroundColor: theme.backgroundColor,

		}),

		option: (provided, state) => {
			return ({
				...provided,
				backgroundColor: state.isSelected ? theme.theme3 : state.isFocused ? theme.theme2 : "transparent",
				color: state.isFocused ? '#000' : theme.fontColor,
				padding: 8,
			});
		},

		control: (_, {selectProps: {width}}) => ({
			..._,
			border: 'none',
			backgroundColor: theme.backgroundColor,
			fontFamily: "'Poppins', sans-serif",
			height: 44,
			outline: 'none',
			boxShadow: "none",

		}),

		multiValue: (styles, {data}) => {
			return {
				...styles,
				backgroundColor: theme.elevation4,
			};
		},

		multiValueLabel: (styles, {data}) => ({
			...styles,
			color: theme.fontColor,
		}),

		placeholder: (_) => ({
			..._,
			color: '#686868',
			fontSize: 15,
		}),

		singleValue: (_) => ({
			..._,
			color: theme.fontColor,
		}),

	};

	// ------------------------------------------------------------------- //

	function handleStepsEnChange(event) {
		let vals = [...stepsEnValues.val];
		vals[this] = event.target.value;
		setStepsEnValues({val: vals});
	}

	const addStepsEnClick = () => {
		setStepsEnValues({val: [...stepsEnValues.val, '']});
	};

	const removeStepsEnClick = (event) => {
		let vals = [...stepsEnValues.val];
		vals.splice(event, 1);
		setStepsEnValues({val: vals});
	};

	function createStepsEnInputs() {
		return stepsEnValues.val.map((el, i) =>
			<div key={i}>
				<Input theme={theme} type="text" value={el || ''} placeholder={text.step + " " + (i + 1)} onChange={handleStepsEnChange.bind(i)}/>
				<i className="iconsminds-close" style={{marginInlineStart: 12}} onClick={() => {
					removeStepsEnClick(i);
				}} value={"remove"}/>
			</div>,
		);
	}

	// ------------------------------------------------------------------- //

	// ------------------------------------------------------------------- //

	function handleIngredientAmountChange(event) {
		let vals = [...ingredientsValues.val];
		vals[this].amount = event.target.value;
		setIngredientsValues({val: vals});
	}

	function handleIngredientsIdChange(event) {
		let vals = [...ingredientsValues.val];
		vals[this].ingredientId = event.value;
		setIngredientsValues({val: vals});
	}

	function handleUnitsIdChange(event, index) {
		let vals = [...ingredientsValues.val];
		vals[index].unitId = event.value;
		setIngredientsValues({val: vals});
	}

	const addIngredientsClick = () => {
		setIngredientsValues({val: [...ingredientsValues.val, {amount: '', unitId: "", ingredientId: ""}]});
	};

	const removeIngredientClick = (index) => {

		let vals = [...ingredientsValues.val];
		vals.splice(index, 1);

		setIngredientsValues({val: vals});

	};

	function createIngredientsInputs() {
		return ingredientsValues.val.map((el, i) =>
			<div key={i}>

				<Input theme={theme} type={"number"} step={".01"} value={el.amount || ''} placeholder={text.amount} onChange={handleIngredientAmountChange.bind(i)}/>

				<CreatableSelect

					isLoading={units.length < 1}

					styles={customStyles}

					placeholder={text.unit}

					value={el.unitId == "" ? "" : units.filter((x) => {
						return x.id === el.unitId;
					}).reduce((acc, curr) => {
						return {value: curr.id, label: lang === "ar" ? curr.name_ar : curr.name_en};
					}, {})}

					options={units.reduce((acc, curr) => {
						acc.push({value: curr.id, label: lang === "ar" ? curr.name_ar : curr.name_en});
						return acc;
					}, [])}

					onCreateOption={(e) => {
						setTypeToAdd(0);
						setCreateNewIngredient(true);
					}}

					onChange={(e) => {
						handleUnitsIdChange(e, i);
					}}

				/>

				<CreatableSelect

					isLoading={ingredients.length < 1}

					styles={customStyles}

					placeholder={text.ingredient}

					value={el.ingredientId == "" ? "" : ingredients.filter((x) => {
						return x.id === el.ingredientId;
					}).reduce((acc, curr) => {
						return {value: curr.id, label: lang === "ar" ? curr.name_ar : curr.name_en};
					}, {})}


					options={ingredients.reduce((acc, curr) => {
						acc.push({value: curr.id, label: lang === "ar" ? curr.name_ar : curr.name_en});
						return acc;
					}, [])}

					onCreateOption={(e) => {
						setTypeToAdd(1);
						setCreateNewIngredient(true);
					}}

					onChange={handleIngredientsIdChange.bind(i)}

				/>

				<i className="iconsminds-close" style={{marginInlineStart: 12}} onClick={() => {
					removeIngredientClick(i);
				}} value={"remove"}/>
			</div>,
		);
	}

	// ------------------------------------------------------------------- //

	function handleStepsArChange(event) {
		let vals = [...stepsArValues.val];
		vals[this] = event.target.value;
		setStepsArValues({val: vals});
	}

	const addStepsArClick = () => {
		setStepsArValues({val: [...stepsArValues.val, '']});
	};

	const removeStepsArClick = (index) => {
		let vals = [...stepsArValues.val];
		vals.splice(index, 1);
		setStepsArValues({val: vals});
	};

	function createStepsArInputs() {
		return stepsArValues.val.map((el, i) =>
			<div key={i}>
				<Input theme={theme} type="text" value={el || ''} placeholder={text.step + " " + (i + 1)} onChange={handleStepsArChange.bind(i)}/>
				<i className="iconsminds-close" style={{marginInlineStart: 12}} onClick={() => {
					removeStepsArClick(i);
				}} value={"remove"}/>
			</div>,
		);
	}

	// ------------------------------------------------------------------- //

	function sendIngredient() {
		setIsLoading(true);

		let jwt = localStorage.getItem('token');

		if (jwt) {
			const data = new FormData();
			//authentication
			data.append("name_en", nameEn);
			data.append("name_ar", nameAr);
			data.append("jwt", jwt);

			fetch(`${servicePath}insert-ingredient`, {
				method: 'POST',
				body: data,
				headers:
					{
						"Accept": "application/json",
					},
			}).then(function (res) {
				return res.json();
			}).then(function (data) {
				if (data == 1) {
					setIsLoading(false);
					setCreateNewIngredient(false);
					getIngredients();
				} else if (data == -2) {
					setIsLoading(false);
					NotificationManager.error("يوجد مكون بنفس الاسم", '', 5000, null, null, '');
				} else {
					setIsLoading(false);
					NotificationManager.error("حدث خطاء في الإتصال تأكد من اتصالك بالأنترنيت", 'خظاء في الإتصال', 5000, null, null, '');
				}
			}).catch(function (error) {
				setIsLoading(false);
			});
		}
	}

	function sendUnit() {
		setIsLoading(true);

		let jwt = localStorage.getItem('token');

		if (jwt) {

			const data = new FormData();
			//authentication
			data.append("name_en", nameEn);
			data.append("name_ar", nameAr);
			data.append("jwt", jwt);

			fetch(`${servicePath}insert-unit`, {
				method: 'POST',
				body: data,
				headers:
					{
						"Accept": "application/json",
					},
			}).then(function (res) {
				return res.json();
			}).then(function (data) {
				if (data == 1) {
					setIsLoading(false);
					setCreateNewIngredient(false);
					getUnits();
				} else {
					setIsLoading(false);
					NotificationManager.error("حدث خطاء في الإتصال تأكد من اتصالك بالأنترنيت", 'خظاء في الإتصال', 5000, null, null, '');
				}
			}).catch(function (error) {
				setIsLoading(false);
			});

		}
	}

	return (
		<div className={classes.mainContainer + " container"}>

			<Loading visible={isLoading}/>

			<div className={classes.addNewIngredient} style={{display: createNewIngredient ? "flex" : "none"}}>
				<div ref={clickOutSideRef}>
					<div style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}>

						<h2>
							{typeToAdd == 0 ? text.addUnit : text.addIngredient}
						</h2>

						<i style={{
							cursor: "pointer",
						}} className="simple-icon-close" onClick={() => setCreateNewIngredient(false)}/>

					</div>

					<hr/>

					<div className={classes.modalInputContainer}>
						<Input theme={theme} style={{padding: 8}} value={nameAr} onChange={(e) => {
							setNameAr(e.target.value);
						}} placeholder={text.nameAr}/>
						<Input theme={theme} style={{padding: 8}} value={nameEn} onChange={(e) => {
							setNameEn(e.target.value);
						}} placeholder={text.nameEn}/>
					</div>

					<Button theme={theme} lang={lang} onClick={() => {
						if (typeToAdd === 1) {
							sendIngredient();
						} else {
							sendUnit();
						}
					}
					}>
						{text.submit}
					</Button>
				</div>
			</div>


			<h1>
				{text.submitForm}
			</h1>


			<div className={classes.form}>

				<label htmlFor="ppuploadinput">
					<div>

						<input type="file" value="" onChange={(e) => {
							setRecipePic(e.currentTarget.files[0]);
						}
						} accept=".gif,.jpg,.jpeg,.png,.bmp" id="ppuploadinput" style={{
							position: "absolute",
							width: 1,
							height: 1,
							padding: 0,
							overflow: "hidden",
							clip: "rect(0, 0, 0, 0)",
							whiteSpace: "nowrap",
							border: 0,
						}}/>

						<img src={recipePic ? URL.createObjectURL(recipePic) : "/pictures/placeholder-image.png"}/>

						{/*<img src="/pictures/placeholder-image.png" />*/}

					</div>
				</label>
				<Select
					className={classes.langSelect}

					options={[
						{value: '0', label: text.enAndAr},
						{value: '1', label: text.arabic},
						{value: '2', label: text.english},
					]}

					placeholder={text.enAndAr}

					styles={customStyles}

					value={recipeLang === 1 ? {value: '1', label: text.arabic} :
						recipeLang === 2 ? {value: '2', label: text.english} : {value: '0', label: text.enAndAr}}
					onChange={(e) => {
						setRecipeLang(+e.value);
					}}
				/>


				<div className={classes.Inner}>


					<Input theme={theme} placeholder={text.recipeNameEn} style={recipeLang == 1 ? {display: "none"} : {display: "flex"}} onChange={(e) => setRecipeNameEn(e.target.value)} value={recipeNameEn}/>

					<Input theme={theme} placeholder={text.recipeNameAr} style={recipeLang == 2 ? {display: "none"} : {display: "flex"}} onChange={(e) => setRecipeNameAr(e.target.value)} value={recipeNameAr}/>

					<TextArea theme={theme} placeholder={text.recipeDescriptionEn} style={recipeLang == 1 ? {display: "none"} : {display: "flex"}} onChange={(e) => setRecipeDescriptionEn(e.target.value)} value={recipeDescriptionEn}>

					</TextArea>

					<TextArea theme={theme} placeholder={text.recipeDescriptionAr} style={recipeLang == 2 ? {display: "none"} : {display: "flex"}} onChange={(e) => setRecipeDescriptionAr(e.target.value)} value={recipeDescriptionAr}>

					</TextArea>

					<Select
						options={[
							{value: '1', label: text.diffEasy},
							{value: '2', label: text.diffMedium},
							{value: '3', label: text.diffHard},
						]}

						placeholder={text.diff}

						styles={customStyles}

						value={difficulty}
						onChange={(e) => {
							setDifficulty(e);
						}}
					/>

					<Input theme={theme} placeholder={text.prepTime} type={"number"} onChange={(e) => setPreparationTime(e.target.value)} value={preparationTime}/>

					<div className={classes.expandable}>

						<span>
							{text.ingredients}
						</span>

						{createIngredientsInputs()}

						<Button theme={theme} style={{
							marginTop: 8,
							width: "100%",
							marginLeft: 0,
							padding: 6,
						}} onClick={addIngredientsClick}>
							{text.add}
						</Button>

					</div>

					<div className={classes.expandable} style={recipeLang == 1 ? {display: "none"} : {display: "flex"}}>

						<span>
							{text.stepsEn}
						</span>

						{createStepsEnInputs()}

						<Button theme={theme} style={{
							marginTop: 8,
							width: "100%",
							marginLeft: 0,
							padding: 6,
						}} onClick={addStepsEnClick}>
							{text.add}
						</Button>

					</div>

					<div className={classes.expandable} style={recipeLang == 2 ? {display: "none"} : {display: "flex"}}>

						<span>
							{text.stepsAr}
						</span>

						{createStepsArInputs()}

						<Button theme={theme} style={{
							marginTop: 8,
							width: "100%",
							marginLeft: 0,
							padding: 6,
						}} onClick={addStepsArClick}>
							{text.add}
						</Button>

					</div>

					<TextArea theme={theme} placeholder={text.notesEn} style={recipeLang == 1 ? {display: "none"} : {display: "flex"}} onChange={(e) => setNotesEn(e.target.value)} value={notesEn}>

					</TextArea>

					<TextArea theme={theme} placeholder={text.notesAR} style={recipeLang == 2 ? {display: "none"} : {display: "flex"}} onChange={(e) => setNotesAr(e.target.value)} value={notesAr}>

					</TextArea>

					<div style={{gridColumn: "1 / -1"}}>

						<Select

							isLoading={categories.length < 1}

							styles={customStyles}

							isMulti

							placeholder={text.categories}

							options={categories.reduce((acc, curr) => {
								acc.push({value: curr.id, label: lang === "ar" ? curr.name_ar : curr.name_en});
								return acc;
							}, [])}

							value={selectedCategories}

							onChange={(e) => {
								setSelectedCategories(e);
							}}

						/>

					</div>


				</div>

				<Button theme={theme} onClick={handleSend}>
					{text.send}
				</Button>

			</div>

		</div>
	)
		;
}

export default SubmitRecipe;
