import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {ThemeContext} from "../../context/ThemeContext";
import {LangContext} from "../../context/LangContext";
import {PageHeader} from "../../StyledComponents";
import SideImage from "../../component/SideImage";
import {LEFT, RIGHT} from "../../component/SideImage";
import ListedRecipe from "../../component/ListedRecipe";
import Lottie from "react-lottie-player";
import recipesLottie from "../../assets/lottie/recipes.json";
import {motion, transform} from "framer-motion";
import Loading from "../../component/Loading";
import {servicePath} from "../../constants/Values";
import {NotificationManager} from "react-notifications";

function Home(props) {

	const useStyle = makeStyles({

		mainContainer: {
			display: "flex",
			flexDirection: "column",
		},

		whatWeOffer: {
			paddingTop: 20,

			alignItems: "center",
			display: "grid",
			gridTemplateColumns: "repeat(2, minmax(360px, 1fr))",

			"@media only screen and (max-width: 1000px)": {
				gridTemplateColumns: "repeat(1, minmax(360px, 1fr))",
			},

			"& > p": {
				fontSize: 18,
				opacity: 0.9,
			},

			"& > div": {
				justifySelf: "center",
			},

		},

	});

	const {theme, toggleTheme} = useContext(ThemeContext);
	const {lang, toggleLang} = useContext(LangContext);

	const [recipes, setRecipes] = useState([]);

	const classes = useStyle({...theme, lang: lang});

	const text =
		lang === "ar" ?
			{
				famousRecipes: "الوصفات المشهورة",
				whatWeOffer: "ماذا نقدم",
				whatWeOfferP: "تم استخراج الخصائص من 73795 وصفة مأخوذة من Recipe Source. يتم استخلاص خصائص المكونات من قائمة المكونات ، والتي تتضمن بعض إجراءات الطهي ، ومن التحضير النصي ، والذي يتم من خلاله استخلاص الإجراءات المطبقة على المكونات باستخدام أدوات البرمجة اللغوية العصبية [8]. يستخدم النهج الأخير كلاً من أساليب التحليل الصرفي النحوي الكلاسيكية وتمثيل الخطاب الذي تم تطويره خصيصًا للنصوص الإجرائية (نصوص التعليمات ، بما في ذلك الوصفات). تم تكييف تمثيل الخطاب جيدًا لحل بعض الظواهر الجذابة المعقدة التي تظهر في تلك الأنواع من النصوص. ويرد في الشكل 4. مثال على وصفة مستخدمة لاستخراج الخاصية. ويتم استخراج خصائص قائمة المكونات من خلال عملية التعليقات التوضيحية التلقائية ، لأن الوصفات تكون في البداية في شكل نصي. هذا التعليق التوضيحي ",
			}
			:
			{
				famousRecipes: "Famous recipes",
				whatWeOffer: "What we offer",
				whatWeOfferP: "Properties are extracted from 73795 recipes taken from Recipe Source. Ingredient properties are extracted both from the ingredient list, which includes some cooking actions, and from the textual preparation, from which the actions applied to the ingredients are extracted using NLP tools [8]. The latter approach uses both classical morpho-syntactic analysis methods and a discourse representation specifically devel- oped for procedural texts (instruction texts, including recipes). The discourse representation is well adapted for solving some complex anaphoric phenomena appearing in those types of texts. An example of a recipe used for property extraction is given in Fig. 4. Extracting the properties of the ingredient list is done through an automatic annotation process, because the recipes are initially in a textual form. This annotation",
			};


	const [recipesAnimation, setRecipesAnimation] = useState(false);

	useEffect(() => {
		getFamousRecipes();
	}, []);

	function getFamousRecipes() {

		const data = new FormData();
		//authentication
		data.append("lang", localStorage.getItem("lang") == '1' ? '2' : '1');

		fetch(`${servicePath}get-famous-recipes`, {method: 'POST', body: data})
			.then(response => response.json())
			.then(recipes => {
				setRecipes(recipes)
				setTimeout(() => {
					setRecipesAnimation(true);
				}, 900);
			});
	}

	return (
		<div className={classes.mainContainer}>

			<SideImage side={RIGHT} picName={"gazra.png"}>
				<motion.div initial={false} transition={{duration: .5}} animate={recipesAnimation ? {
					y: 0,
					opacity: 1,
				} : {y: -70, opacity: 0}} className={"container"}>

					<PageHeader theme={theme} lang={lang}>
						{text.famousRecipes}
					</PageHeader>

					<div className={'recipesContainer'}>
						{recipes.length > 0 ? recipes.map((recipe) => {
							return <ListedRecipe {...recipe} filterReducer={props.filtersReducer} />;
						}) : <div style={{position: "relative"}}><Loading visible={true}/></div>}
					</div>

				</motion.div>
			</SideImage>

			<SideImage side={LEFT} picName={"lamona.png"}>
				<div className={"container"}>

					<PageHeader theme={theme} lang={lang}>
						{text.whatWeOffer}
					</PageHeader>

					<div className={classes.whatWeOffer}>

						<p>
							{text.whatWeOfferP}
						</p>

						<Lottie animationData={recipesLottie} play={true} speed={1} loop={true} style={{width: "70%"}}/>

					</div>

				</div>
			</SideImage>


		</div>
	);
}

export default Home;
