import React, {useContext, useEffect, useReducer} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch, useHistory} from "react-router-dom";
import {adminRoot} from "./constants/Values";
import {makeStyles} from "@material-ui/styles";
import {ThemeContext} from "./context/ThemeContext";
import {LangContext} from "./context/LangContext";
import {AnimatePresence} from "framer-motion";
import {NotificationContainer} from "react-notifications";
import {UserContext} from "./context/userContext";
import Loading from "./component/Loading";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Home from "./views/Home/Home";
import FilterArea from "./component/FilterArea";
import SubmitRecipe from "./views/Recipes/SubmitRecipe";
import PasswordForget from "./views/account/PasswordForget";

const SingleRecipe = React.lazy(() =>
	import("./views/Recipes/SingleRecipe"),
);

const Register = React.lazy(() =>
	import("./views/account/Register"),
);

const Login = React.lazy(() =>
	import("./views/account/Login"),
);

const Profile = React.lazy(() =>
	import("./views/account/Profile"),
);

const Recipes = React.lazy(() =>
	import("./views/Recipes/Recipes"),
);

const Privacy = React.lazy(() =>
	import("./views/account/privacy"),
);

function reducer(state, action) {
	switch (action.type) {
		case "clear": {
			return {recipeName: "", ingredients: [], category: {}};
		}

		case "setCategory": {
			let tempState = {...state};

			if (tempState.category === action.payload) {
				tempState.category = {};
			} else {
				tempState.category = action.payload;
			}

			return tempState;
		}

		case "setRecipeName": {
			let tempState = {...state};

			tempState.recipeName = action.payload;

			return tempState;
		}

		case "toggleIngredient": {
			let tempState = {...state};

			if (tempState.ingredients.includes(action.payload)) {
				for (let i = tempState.ingredients.length - 1; i >= 0; i--) {
					if (tempState.ingredients[i] === action.payload) {
						tempState.ingredients.splice(i, 1);
					}
				}
			} else {
				tempState.ingredients.push(action.payload);
			}

			return tempState;
		}


		default: {
			return state;
		}
	}
}

function App() {

	const {theme} = useContext(ThemeContext);
	const {lang} = useContext(LangContext);
	const {getUserInfo} = useContext(UserContext);

	const history = useHistory();

	const [state, dispatch] = useReducer(reducer, {recipeName: "", ingredients: [], category: {}});

	const GlobalStyles = makeStyles({
		'@global': {
			'.container': {
				boxSizing: "border-box",
				width: "100%",
				paddingRight: "0.5rem",
				paddingLeft: "0.5rem",
				marginRight: "auto",
				marginLeft: "auto",
				transition: "background 300ms ease-out",
				background: theme => `${theme.color}`,
			},

			'.recipesContainer': {
				marginTop: 50,
				display: "grid",
				boxSizing: "border-box",
				gridTemplateColumns: "repeat(auto-fill, minmax(360px, 1fr))",
				alignItems: "start",
				gap: 30,
				gridTemplateRows: 600,

				"& > div": {
					height: "100%",
				},
			},

			'@media (min-width: 576px)': {
				'.container': {
					maxWidth: "540px",
				},
			},

			'@media (min-width: 768px)': {
				".container": {
					maxWidth: "720px",
				},
			},

			'@media (min-width: 992px)': {
				".container": {
					maxWidth: "960px",
				},
			},

			'@media (min-width: 1200px)': {
				".container": {
					maxWidth: "1140px",
				},
			},

			'@media (min-width: 1400px)': {
				".container": {
					maxWidth: "1320px",
				},
			},

			'body': {
				padding: "0",
				margin: "0",
				fontFamily: "'Poppins', sans-serif",
			},

			'html': {
				color: theme => theme.fontColor,
				backgroundColor: theme => theme.backgroundColor,

				scrollbarWidth: "thin",
				scrollbarColor: theme => `${theme.scrollbarColor} ${theme.scrollbarTrackColor}`,

				'&::-webkit-scrollbar-thumb': {
					backgroundColor: theme => theme.scrollbarColor,
					border: theme => theme.scrollbarBorder,
				},

				'&::-webkit-scrollbar-track': {
					backgroundColor: theme => theme.scrollbarTrackColor,
				},

				'&::-webkit-scrollbar': {
					width: theme => theme.scrollbarWidth,
				},
			},

			'a': {
				textDecoration: "none",
			}
			,
			"a:hover": {
				color: theme => [[theme.theme2], "!important"],
			},

			'*': {
				transition: "background-color 300ms ease-out",
			},


		},
	});

	GlobalStyles(theme);

	if (lang === "ar") {
		document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
	} else {
		document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
	}

	useEffect(() => {
		if (state.recipeName !== "" || state.ingredients.length > 0 || state.category.id) {
			history.push(`${adminRoot}home/recipes`);
		}
	}, [state]);

	useEffect(() => {
		getUserInfo();
	}, []);

	return (
		<div>

			<NotificationContainer/>

			<Header/>


			<Route
				path={`${adminRoot}home`}
				render={(props) => <FilterArea filters={state} filtersReducer={dispatch}/>}
			/>


			<AnimatePresence>
				<Switch>
					<Route
						path={`${adminRoot}home`}
						exact
						render={(props) => <Home filters={state} filtersReducer={dispatch} />}
					/>

					<Route
						path={`${adminRoot}home/recipes`}
						exact
						render={(props) => <Recipes filters={state} filtersReducer={dispatch}/>}
					/>
				</Switch>
			</AnimatePresence>

			<Route
				path={`${adminRoot}recipe`}
				render={(props) => <SingleRecipe filters={state} filtersReducer={dispatch}/>}
			/>

			<Route
				path={`${adminRoot}register`}
				render={(props) => <Register/>}
			/>

			<Route
				path={`${adminRoot}login`}
				render={(props) => <Login/>}
			/>

			<Route
				path={`${adminRoot}password-forget`}
				render={(props) => <PasswordForget/>}
			/>

			<Route
				path={`${adminRoot}profile`}
				render={(props) => <Profile filters={state} filtersReducer={dispatch}/>}
			/>

			<Route
				exact
				path={`${adminRoot}privacy`}
				render={(props) => <Privacy {...props} />}
			/>

			<Route
				path={`${adminRoot}submit-recipe`}
				render={(props) => <SubmitRecipe props={props}/>}
			/>

			<Route exact path="/">
				<Redirect to="/home"/>
			</Route>

			<Footer filters={state} filtersReducer={dispatch}/>



		</div>
	);
}

export default App;
