import React, {useContext, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import SideImage, {LEFT, RIGHT} from "../../component/SideImage";
import {Button, Input} from "../../StyledComponents";
import {NavLink, useHistory} from "react-router-dom";
import {ThemeContext} from "../../context/ThemeContext";
import {LangContext} from "../../context/LangContext";
import {servicePath} from "../../constants/Values";
import {NotificationManager} from "react-notifications";
import Loading from "../../component/Loading";


function PasswordForget(props) {

	const {theme, toggleTheme} = useContext(ThemeContext);
	const {lang, toggleLang} = useContext(LangContext);

	const [newPassword, setNewPassword] = useState("");
	const [reNewPassword, setReNewPassword] = useState("");

	const [isLoading, setIsLoading] = useState("");

	const history = useHistory();

	let search = window.location.search;
	let params = new URLSearchParams(search);

	let rkey = params.get('RKey');
	let id = params.get('TUID');

	if (!(rkey && id)) {
		history.push("/register")
	}

	const useStyle = makeStyles({
		mainContainer: {
			display: "flex",
			flexDirection: "column",
			background: theme => theme.elevation2,
			borderRadius: 12,
			padding: 24,
			marginTop: 64,
			maxWidth: 600,
			margin: "0 auto",
			gap: 18,

			"& > h1": {
				margin: 0,
				padding: 0,

				marginBottom: 12,
			},

			"& a": {
				color: theme => theme.fontColor,
			},

			"& button": {
				margin: 0,
			},

			"& button:not(:last-child)": {
				marginInlineEnd: 12,
			},

			"& > p": {
				margin: 0,
				padding: 0,
				color: theme => theme.errorColor,
			},

		},

		Inputs: {
			display: "flex",
			flexWrap: "wrap",
			gap: 12,

			"& > input": {
				padding: 12,
				flexGrow: 1,

			},

		},

		forgotPasswordDialog: {
			display: "flex",
			position: "fixed",
			width: "100%",
			height: "100%",
			padding: 24,
			top: 0,
			left: 0,
			background: "#000000ae",
			zIndex: 9,
			alignItems: "center",
			justifyContent: "center",
			boxSizing: 'border-box',

			"& input": {
				border: theme => `1px solid ${theme.theme1}`,
				width: "100%",
				padding: 12,
				boxSizing: "border-box",
				marginBottom: 8,

				"&:focus": {
					border: theme => `1px solid ${theme.theme2}`,
				},
			},

			"& > div": {
				width: 400,
				maxWidth: "90%",
				padding: 24,
				background: theme => theme.backgroundColor,

				"& h2": {
					padding: 0,
					margin: 0,
				},

				"& textarea:focus": {
					border: theme => `${theme.theme2} 1px solid`,
				},

				"& button": {
					width: 265,
				},

			},
		},

	});

	const classes = useStyle(theme);

	const text =
		lang === "ar" ?
			{
				restPassword: "إعادة تعيين كلمة المرور",
				password: "كلمه السر",
				rePassword: "اعد كلمة السر",
				loginAction: "تسجيل الدخول",
				makeSureFieldsAreNotEmpty: "يرجى التأكد من أن الحقول ليست فارغة",
				unknownError: "خطاء غير معروف حاول وقت اخر",
				networkError: "حدث خطاء في الإتصال تأكد من اتصالك بالأنترنيت",
				done: "تم",
				passwordMatch: "كلمة المرور غير متطابقة",
			}
			:
			{
				restPassword: "ResetPassword",
				password: "password",
				rePassword: "Repeat password",
				loginAction: "Login",
				makeSureFieldsAreNotEmpty: "Please make sure that the fields are not empty",
				unknownError: "Unknown issue happened try again later",
				networkError: "A connection error occurred. Make sure you are connected to the Internet",
				done: "Done",
				passwordMatch: "Password doesn't match",
			};

	function resetPassword(e) {
		e.preventDefault();

		if (newPassword && reNewPassword) {

			if (newPassword === reNewPassword) {

				setIsLoading(true);

				const data = new FormData();
				//authentication
				data.append("rkey", rkey);
				data.append("id", id);
				data.append("password", newPassword);

				fetch(`${servicePath}password-reset`, {
					method: 'POST',
					body: data,
					headers:
						{
							"Accept": "application/json",
						},
				}).then(function (res) {
					return res.json();
				}).then(function (data) {
					if (data == "1"){
						history.push("/login");
					}
				}).catch(function (error) {
					setIsLoading(false);
					NotificationManager.error(text.networkError, '', 5000, null, null, '');
				});

			} else {
				NotificationManager.error(text.passwordMatch, '', 5000, null, null, '');
				setIsLoading(false);
			}

		} else {
			NotificationManager.error(text.makeSureFieldsAreNotEmpty, '', 5000, null, null, '');
			setIsLoading(false);
		}

	}


	return (
		<div className={"container"}>

			<Loading visible={isLoading}/>

			<SideImage side={LEFT} picName={"gazra.png"}>
				<form className={classes.mainContainer}
					  onSubmit={resetPassword}
				>

					<h1>
						{text.restPassword}
					</h1>

					<div className={classes.Inputs} style={{display: "flex", justifyContent: "space-between"}}>

						<Input theme={theme} type={"password"} placeHolder={text.password} onChange={(e) => setNewPassword(e.target.value)} value={newPassword}/>

						<Input theme={theme} type={"password"} placeHolder={text.rePassword} onChange={(e) => setReNewPassword(e.target.value)} value={reNewPassword}/>

					</div>

					<div style={{marginInlineStart: "auto"}}>

						<Button theme={theme} onClick={() => history.push("/login")} type={'reset'}>
							{text.loginAction}
						</Button>

						<Button theme={theme}
								onClick={resetPassword}
								type={'submit'}>
							{text.done}
						</Button>

					</div>

				</form>
			</SideImage>
		</div>
	);
}

export default PasswordForget;
