import React, {useContext, useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {ThemeContext} from "../context/ThemeContext";
import {Input} from "../StyledComponents";
import {LangContext} from "../context/LangContext";
import {AnimatePresence, AnimateSharedLayout, motion} from "framer-motion";
import {scrollToRecipes} from "../Helpers";
import {useOutsideAlerter} from "../my-hooks/useOutsideAlerter";
import useDebounce from "../my-hooks/useDebounce";
import {servicePath} from "../constants/Values";
import Select from "react-select";

function Search({filters, filtersReducer}) {
	const clickOutSideRef = useRef(null);

	const {theme, toggleTheme} = useContext(ThemeContext);
	const {lang, toggleLang} = useContext(LangContext);

	const [ingredients, setIngredients] = useState([]);

	const [isIngredientsOpen, setIsIngredientsOpen] = useState(false);
	const [ingredientSearch, setIngredientSearch] = useState("");

	const [recipesNameSearch, setRecipesNameSearch] = useState("");

	useDebounce(() => filtersReducer({type: "setRecipeName", payload: recipesNameSearch}), 1000, [recipesNameSearch]);

	useOutsideAlerter(clickOutSideRef, () => {setIsIngredientsOpen(false)})

	const useStyle = makeStyles({
		mainContainer: {
			display: "flex",
			flexDirection: "column",
		},

		SearchBarContainer: {
			display: "flex",
			justifyContent: "stretch",
			color: theme => theme.fontColor,

			"& > span": {
				display: "flex",
				alignItems: "center",
				height: 62,
				background: theme => theme.backgroundColor,
				paddingInlineEnd: 12,
				color: theme => theme.theme3,
			},

			"& > input:nth-of-type(1)": {
				flexGrow: 1,
				fontSize: 16,
				paddingInlineStart: 12,
			},

		},

		iconContainer: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			background: "#000",
			padding: 6,
			borderRadius: 8,

			"& > i": {
				fontSize: 30,
				color: "#fff",
				padding: 10,
			},
		},

		searchbar: {
			borderTopLeftRadius: theme => theme.lang === "ar" ? "unset" : 8,
			borderTopRightRadius: theme => theme.lang === "ar" ? 8 : "unset",
			borderBottomLeftRadius: theme => theme.lang === "ar" ? "unset" : 8,
			borderBottomRightRadius: theme => theme.lang === "ar" ? 8 : "unset",
		},

		advancedSearch: {
			display: "flex",
			alignItems: "center",
			background: theme => theme.backgroundColor,
			color: theme => theme.muted,
			cursor: "pointer",
			borderRadius: 8,
		},

		mainSelectContainer: {
			display: "flex",
			flexDirection: "column",
			overflow: "visible",
			zIndex: 12,
			position: "relative",
			height: "100%",
		},

		ingredientSearch: {
			height: "100%",
			display: "flex",
			alignItems: "center",
			flexDirection: "column",

			"& > input": {
				width: 150,
				flexGrow: 1,
				borderRadius: 0,

				"&::-webkit-input-placeholder": {
					fontSize: 16,
				},

			},
		},

		autoCompleteMenu: {
			position: "absolute",
			top: "100%",
			width: "100%",
			background: theme => theme.backgroundColor,
			boxSizing: "border-box",
			boxShadow: theme => theme.boxShadow2,
			display: "flex",
			flexDirection: "column",

			"& > span": {
				display: "flex",
				alignItems: "center",
				lineHeight: 1.1,
				padding: 6,
				marginBottom: 4,
				boxSizing: "border-box",
				color: theme => theme.fontColor,
			},

			"& > span:hover": {
				background: theme => `${theme.muted}${theme.name === "light" ? "44" : "88"}`,
			},
		},

		searchTagContainer: {
			display: "flex",
			marginTop: 8,
			gap: 8,
			height: 41,
			width: "100%",
			justifyContent: "center",

			"& > span": {
				borderRadius: 20,
				background: theme => theme.theme4,
				color: theme => theme.theme6,
				padding: 8,
				cursor: "pointer",
			},
		},

		"@media screen and (max-width: 992px)": {
			SearchBarContainer: {
				flexDirection: "column",

				"& > input": {
					height: 62,
					borderRadius: 8,
				},

				"& > span": {
					background: ["none", "!important"],
					alignSelf: "center",
					color: theme => theme.theme2,
					fontSize: 30,
				},
			},

			advancedSearch: {
				justifyContent: "space-between",
				borderRadius: 8,
			},

			ingredientSearch: {
				paddingInlineStart: 12,
			},

			mainSelectContainer: {
				alignItems: "start",
				flexGrow: 1,
			},

			autoCompleteMenu: {
				width: "100%",
			},
		},


	});

	const classes = useStyle({...theme, lang});

	const text =
		lang === "ar" ?
			{
				recipeName: "اسم الوصفة",
				or: "أو",
				ingredient: "مكوناتها",
				noOptions: "ﻻ يوجد",
			}
			:
			{
				recipeName: "Recipe Name",
				or: "Or",
				ingredient: "Ingredient",
				noOptions: "No Options",
			};

	function toggleIsIngredientOpen() {
		setIsIngredientsOpen(!isIngredientsOpen);
	}

	function addTag(tag) {
		setIngredientSearch("");
		setIsIngredientsOpen(false);

		filtersReducer({type: "toggleIngredient", payload: tag});
	}

	function removeTag(tag) {
		filtersReducer({type: "toggleIngredient", payload: tag});
	}

	useEffect(() => {
		getIngredients();
	}, [ingredientSearch])

	function getIngredients() {
		let data = new FormData();
		data.append("text", ingredientSearch);


		fetch(`${servicePath}search-ingredients`, {
				method: "POST",
				body: data
		})
			.then(response => response.json())
			.then(lowerIngredients => {
				setIngredients(lowerIngredients);
			});
	}

	return (
		<div className={classes.mainContainer}>
			<div className={classes.SearchBarContainer}>

				<Input className={classes.searchbar} theme={theme} value={recipesNameSearch} onChange={(e) => {
					setRecipesNameSearch(e.target.value)
				}} placeholder={text.recipeName}/>

				<span>
					{text.or}
				</span>

				<div className={classes.advancedSearch} onClick={() => {
					toggleIsIngredientOpen();
				}}>

					<div className={classes.mainSelectContainer}>
						<div className={classes.ingredientSearch}>
							<Input theme={theme} value={ingredientSearch} onChange={(e) => setIngredientSearch(e.target.value)} placeholder={text.ingredient}/>
						</div>

						<AnimateSharedLayout>
							<motion.div className={classes.autoCompleteMenu} ref={clickOutSideRef} style={{display: isIngredientsOpen || ingredientSearch ? "flex" : "none"}}>
								{ingredients.length > 0 ?
									ingredients.map((ingredient) => {

										let ingredientName = lang === "ar" ? ingredient.name_ar : ingredient.name_en;

										return (
											<motion.span layout initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} onClick={() => addTag(ingredient)}>
												{ingredientName}
											</motion.span>
										);
									})
									:
									<span>
									{text.noOptions}
								</span>
								}
							</motion.div>
						</AnimateSharedLayout>
					</div>

					<div className={classes.iconContainer} onClick={(e) => {
						e.stopPropagation();

						scrollToRecipes();
					}}>

						<i className="simple-icon-magnifier"/>

					</div>
				</div>


			</div>
			<div className={classes.searchTagContainer}>
				{filters.ingredients.map((tag) => {

					let ingredientName = lang === "ar" ? tag.name_ar : tag.name_en;

					return (
						<motion.span layout initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} onClick={() => removeTag(tag)}>
							{ingredientName}
						</motion.span>
					);
				})}
			</div>
		</div>

	);
}

export default Search;
