import {styled} from "@material-ui/styles";

export const Input = styled('input')(({theme}) => ({
	border: "none",
	background: theme.backgroundColor,
	color: theme.fontColor,
	fontFamily: "'Poppins', sans-serif",
	borderRadius: 4,

	"&:active": {
		outline: "none",
	},

	"&:focus": {
		outline: "none",
	},

	"&:disabled": {
		background: theme.elevation4
	}
}));


export const PageHeader = styled('h1')(({theme, lang}) => ({
	color: theme.fontColor,
	position: "relative",

	"&::after": {
		content: "''",
		position: "absolute",
		background: `${theme.muted}${theme.name === "light" ? "44" : "88"}`,
		left: 0,
		bottom: -19,
		width: "100%",
		height: 1,
	},

	"&::before": {
		content: "''",
		position: "absolute",
		background: theme.theme3,
		left: lang === "ar" ? "unset" : 0,
		right: lang === "ar" ? 0 : "unset",
		bottom: -20,
		width: 120,
		height: 3,
	},
}));

export const Button = styled('button')(({theme, lang}) => ({
	background: theme.theme2,
	color: "black",
	fontSize: "1.3em",
	margin: "1em",
	borderRadius: 4,
	padding: "0.50em 1.3em",
	border: 0,
	outline: 0,
	cursor: "pointer",
}));


export const TextArea = styled('textarea')(({theme}) => ({
	border: "none",
	background: theme.backgroundColor,
	color: theme.fontColor,
	fontFamily: "'Poppins', sans-serif",
	borderRadius: 4,

	"&:active": {
		outline: "none",
	},

	"&:focus": {
		outline: "none",
	},

	"&:disabled": {
		background: theme.elevation4
	}
}));

