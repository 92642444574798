export function scrollToRecipes() {
	try {

		const violation = document.getElementById("list-of-recipes");

		window.scrollTo({
			top: violation.offsetTop,
			behavior: "smooth",
		});

	} catch (e) {

	}
}

export const getDateWithFormat = (timeStamp = new Date().getTime()/1000, lang) => {
	const today = new Date(timeStamp*1000);
	let dd = today.getDate();
	let mm = today.getMonth(); // January is 0!

	const months = lang === "en" ?
		{
			month: [
				"January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December",
			],
		}
		:
		{
			month: [
				"يناير", "فبراير", "مارس", "إبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر",
			],
		};

	let monthName = months["month"][mm];

	const yyyy = today.getFullYear();
	if (dd < 10) {
		dd = `0${dd}`;
	}
	return `${monthName} ${dd} ${yyyy}`;
};

