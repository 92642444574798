export const adminRoot = `/`;
export const servicePath = "https://tapkha.com/apis/"

export const difficultyEN = {
	1: "easy",
	2: "medium",
	3: "hard",
}

export const difficultyAR = {
	1: "سهل",
	2: "متوسط",
	3: "صعب",
}
