import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {Counter} from "./Counter";
import Search from "./Search";
import SideImage, {LEFT} from "./SideImage";
import {motion} from "framer-motion";
import Category from "./Category";
import {ThemeContext} from "../context/ThemeContext";
import {LangContext} from "../context/LangContext";
import {scrollToRecipes} from "../Helpers";
import useWindowDimensions from "../my-hooks/useWindowDimentions";
import {useHistory} from "react-router-dom";
import {servicePath} from "../constants/Values";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import SwiperCore, {
	Pagination,
} from 'swiper';

SwiperCore.use([Pagination]);


function FilterArea({filters, filtersReducer}) {

	const {theme} = useContext(ThemeContext);
	const {lang} = useContext(LangContext);

	const {height, width} = useWindowDimensions();

	const history = useHistory();

	const [categories, setCategories] = useState([]);

	const [numberOfRecipes, setNumberOfRecipes] = useState(0);

	const useStyle = makeStyles({

		searchContainer: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			background: "url('/pictures/search-block-bg.jpg')",
			backgroundOrigin: "left center",
			backgroundSize: "cover",
			paddingTop: 80,
			paddingBottom: 80,
			paddingLeft: 0,
			paddingRight: 0,
			position: "relative",

			"& > *": {
				zIndex: 2,
			},

			"& > h1": {
				marginTop: 0,
				color: theme => theme.theme6,
			},

			"& > div": {
				width: "80%",
			},

			"&::before": {
				position: "absolute",
				content: "''",
				top: 0,
				left: 0,
				height: "100%",
				width: "100%",
				background: "#0004",
			},

		},

		jssSwiper: {
			paddingBottom: 40,
			paddingTop: 30,
			paddingLeft: 8,
			paddingRight: 8,

		},

		categories: {
			display: "flex",
			justifyContent: "space-between",
			flexWrap: "wrap",
			marginTop: "3rem",
			gap: 12,

			"@media only screen and (max-width: 600px)": {
				justifyContent: "space-evenly",
			},

		},
	});

	const classes = useStyle(theme);

	const text =
		lang === "ar" ?
			{
				recipe: "وصفة",
				total: "اجمالى",
			}
			:
			{
				recipe: "Recipe",
				total: "Total",
			};

	const [categoriesAnimation, setCategoriesAnimation] = useState(false);

	function getCategories() {
		fetch(`${servicePath}get-categories`)
			.then(response => response.json())
			.then(categories => setCategories(categories));
	}

	function getNumberOfRecipes() {
		fetch(`${servicePath}get-number-of-recipes`)
			.then(response => response.json())
			.then(number => {
				setNumberOfRecipes(+number);
				getCategories();
			});
	}

	useEffect(() => {
		getNumberOfRecipes();

		setTimeout(() => {
			setCategoriesAnimation(true);
		}, 400);
	}, []);


	const categoriesFramerVariants = {
		visible: {
			transition: {
				staggerChildren: 0.15,
			},
		},

		hidden: {
			transition: {
				staggerChildren: 0.05, delayChildren: 0.5, duration: 2,
			},
		},
	};

	history.listen((location) => {
		location.pathname === "/home" && filtersReducer({type: "clear"});
	});

	return (
		<div>

			<div className={classes.searchContainer + " container"}>

				<h1>
					{text.total} <Counter from={0} to={numberOfRecipes}/> {text.recipe}
				</h1>

				<Search filters={filters} filtersReducer={filtersReducer}/>

			</div>

			<SideImage side={LEFT} picName={"basala.png"}>
				<motion.div initial={false} animate={categoriesAnimation ? "visible" : "hidden"} transition={{duration: .5}} variants={categoriesFramerVariants} className={classes.categories + " container"}>
					<Swiper
						dir={lang === "ar" ? "rtl" : "ltr"}
						breakpoints={{
							"300": {
								"slidesPerView": 2,
								"spaceBetween": 15,
							},
							"600": {
								"slidesPerView": 3,
								"spaceBetween": 15,
							},
							"770": {
								"slidesPerView": 4,
								"spaceBetween": 20,
							},
							"993": {
								"slidesPerView": 5,
								"spaceBetween": 40,
							},
							"1200": {
								"slidesPerView": 5,
								"spaceBetween": 60,

							},
							"1920": {
								"slidesPerView": 6,
								"spaceBetween": 60,

							},
						}}
						pagination={{
							"dynamicBullets": true,
							"clickable": true,
						}} className={classes.jssSwiper + " " + "mySwiper"}>

						{categories.length > 0 ? categories.map((category) => {
							return <SwiperSlide>
								<Category key={category.id} name_en={category.name_en} name_ar={category.name_ar} image={category.image_url} amount={category.numberOfRecipes} selected={filters.category.id === category.id}
										  onClick={() => {
											  filtersReducer({type: 'setCategory', payload: category});
											  width < 1200 && scrollToRecipes();
										  }}/>
							</SwiperSlide>;
						}) : <></>}
					</Swiper>
				</motion.div>
			</SideImage>

		</div>
	);
}

export default FilterArea;
