import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, useTheme} from "@material-ui/styles";
import {ThemeContext} from "../context/ThemeContext";
import Lottie from "react-lottie-player";
import checkboxAnimation from '../assets/lottie/checkboxAnimation.json';
import {LangContext} from "../context/LangContext";

function Ingredient({name, handleCheck, checked, id, quantity, unit}) {

	const {theme} = useContext(ThemeContext);
	const {lang} = useContext(LangContext);

	const useStyle = makeStyles({
		mainContainer: {
			display: "flex",
			alignItems: "center",

			"& span": {
				fontSize: 18,
				position: "relative",
				left: theme => theme.lang === "ar" ? "unset" : -10,
				right: theme => theme.lang === "ar" ? -10 : "unset",
			},
		},
	});

	const classes = useStyle({...theme, lang});

	const [isPlaying, setIsPlaying] = useState(false);

	useEffect(() => {
		setIsPlaying(true);
	}, [checked]);

	return (
		<div className={classes.mainContainer} onClick={handleCheck} data-key={id}>

			<Lottie animationData={checkboxAnimation} segments={[0, 25]} speed={3} direction={checked ? 1 : -1} play={isPlaying} loop={false} onComplete={() => {
				setIsPlaying(false);
			}} style={{
				height: 50,
				width: 50,
				margin: 0,
				cursor: "pointer",
				position: "relative",
				left: lang === "ar" ? "unset" : -10,
				right: lang === "ar" ? -10 : "unset",
			}}/>

			<span style={{textDecoration: checked ? "line-through" : "none"}}>
				{quantity} {unit} {name}
			</span>

		</div>
	);
}

export default Ingredient;
