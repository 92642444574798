import React, {useContext} from 'react';
import {makeStyles, useTheme} from "@material-ui/styles";
import Lottie from "react-lottie-player";
import loading from "../assets/lottie/loading.json";
import {ThemeContext} from "../context/ThemeContext";

function Loading({visible}) {

	const theme = useContext(ThemeContext);


	const useStyle = makeStyles({});

	const classes = useStyle();

	return (
		<div style={{
			position: "fixed",
			zIndex: 99,
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			background: theme.name === "dark" ? "#ffffff87" : "#00000087",
			display: visible ? "flex" : "none",
			justifyContent: "center",
			alignItems: "center",
		}}>
			<Lottie
				loop
				animationData={loading}
				play
				style={{width: "20%", height: "20%", position: "relative", left: "5%", top: "-20%", minHeight: 250, minWidth: 250}}
			/>
		</div>
	);
}

export default Loading;
