import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';
import {adminRoot} from "./constants/Values";
import {ThemeProvider} from "./context/ThemeContext";
import {LangProvider} from "./context/LangContext";
import {UserProvider} from "./context/userContext";
import Loading from "./component/Loading";
import 'react-notifications/lib/notifications.css';

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider>
			<LangProvider>
				<UserProvider>
					<React.Suspense fallback={<Loading visible={true}/>}>
						<Router>
							<App/>
						</Router>
					</React.Suspense>
				</UserProvider>
			</LangProvider>
		</ThemeProvider>
	</React.StrictMode>
	,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(
	{
		onUpdate: registration => {
			const waitingServiceWorker = registration.waiting;

			if (waitingServiceWorker) {
				waitingServiceWorker.addEventListener("statechange", event => {
					if (event.target.state === "activated") {
						window.location.reload();
					}
				});
				waitingServiceWorker.postMessage({type: "SKIP_WAITING"});
			}
		},
	},
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
