import React, {useContext, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {NavLink} from "react-router-dom";
import {adminRoot, servicePath} from "../constants/Values";
import {ThemeContext} from "../context/ThemeContext";
import Lottie from 'react-lottie-player';
import darkModeOn from '../assets/lottie/darkModeOn.json';
import darkModeOff from '../assets/lottie/darkModeOff.json';
import loading from '../assets/lottie/loading.json';
import {LangContext} from "../context/LangContext";
import useWindowDimensions from "../my-hooks/useWindowDimentions";
import {useHistory} from "react-router-dom";
import {Button} from "../StyledComponents";
import {NotificationManager} from "react-notifications";
import {UserContext} from "../context/userContext";
import {useOutsideAlerter} from "../my-hooks/useOutsideAlerter";


function Header(userData) {
	const clickOutSideRef = useRef(null);

	const {height, width} = useWindowDimensions();
	const history = useHistory();

	const {theme, toggleTheme} = useContext(ThemeContext);
	const {lang, toggleLang} = useContext(LangContext);
	const {userInfo, getUserInfo} = useContext(UserContext);

	const [isPlaying, setIsPlaying] = useState(false);

	const [menuVisable, setMenuVisable] = useState(false);

	const [userMenuVisible, setUserMenuVisible] = useState(false);

	const useStyle = makeStyles({
		mainContainer: {
			height: 80,
			width: "100%",
			background: theme => theme.elevation4,
			boxShadow: theme => theme.boxShadow,
		},

		innerContainer: {
			height: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
		},

		links: {
			display: "flex",
			alignItems: "center",
			marginInlineStart: "auto",

			"& > a": {
				fontSize: 18,
				color: theme => theme.fontColor,
			},

			"& > a:not(:last-child)": {
				marginInlineEnd: 12,
			},

			"@media only screen and (max-width: 780px)": {
				position: "fixed",
				right: theme => theme.lang === "ar" ? "unset" : 4,
				left: theme => theme.lang === "ar" ? 4 : "unset",
				margin: 0,
				top: 76,
				flexDirection: "column",
				zIndex: 999,
				background: theme => theme.backgroundColor,
				padding: 12,
				borderRadius: 12,
				border: theme => `1px solid ${theme.theme1}`,
				minWidth: 130,
				alignItems: "stretch",

				"& > a": {
					padding: 12,
					marginInlineEnd: [0, "!important"],
				},

				"&::before": {
					content: "''",
					position: "absolute",
					left: 0,
					top: 0,
					width: "100%",
					height: "100%",
					background: theme => theme.elevation4,
					zIndex: -1,
				},
			},

		},

		activeLink: {
			color: theme => [[theme.theme1], "!important"],
		},

		logo: {
			height: 60,
		},

		lottieHolder: {
			cursor: "pointer",
		},

		langSwitch: {
			cursor: "pointer",
		},

		userSection: {
			display: "flex",
			alignItems: "center",
			gap: 6,
			marginInlineEnd: 16,
			cursor: "pointer",

			"& > i": {
				fontSize: 24,
			},

			"& > img": {
				width: 32,
				height: 32,
			},
		},

		userMenu: {
			display: "flex",
			position: "absolute",
			background: theme => theme.backgroundColor,
			boxSizing: "border-box",
			boxShadow: theme => theme.boxShadow2,
			flexDirection: "column",
			width: 120,
			top: 80,
			zIndex: 99,
			borderBottomRightRadius: 12,
			borderBottomLeftRadius: 12,


			"&::before": {
				content: "''",
				width: "100%",
				height: "100%",
				position: 'absolute',
				background: theme => theme.elevation4,
				pointerEvents: "none",
				zIndex: theme => theme.name === "dark" ? 2 : -1,
			},

			"& > span": {
				height: 40,
				display: "flex",
				alignItems: "center",
				padding: 6,
				boxSizing: "border-box",
				color: theme => theme.fontColor,
			},

			"& > span:hover": {
				background: theme => `${theme.muted}${theme.name === "light" ? "44" : "88"}`,
			},
		},

	});

	const classes = useStyle({...theme, lang: lang});

	useOutsideAlerter(clickOutSideRef, () => {
		setUserMenuVisible(false);
	});

	const text =
		lang === "ar" ?
			{
				home: "الرئيسية",
				recipes: "الوصفات",
				yourAccount: "حسابك",
				submitRecipe: "قدم وصفة",
				profile: "الملف الشخصي",
				logout: "تسجيل خروج",
			}
			:
			{
				home: "Home",
				recipes: "Recipes",
				yourAccount: "Account",
				submitRecipe: "Submit a recipe",
				profile: "Profile",
				logout: "logout",
			};

	history.listen((location, action) => {
		setMenuVisable(false);
	});

	function logout() {
		localStorage.removeItem("token");
		history.push("/home");
		getUserInfo();
	}

	return (
		<div className={classes.mainContainer}>
			<div className={classes.innerContainer + " container"}>

				<div onClick={() => history.push("/home")} style={{cursor: "pointer"}}>
					<img src={theme.name === "light" ? "/svgs/logo-light.svg" : "/svgs/logo-dark.svg"} alt={"company logo"} className={classes.logo}/>
				</div>


				<div className={classes.links} style={{display: width < 780 && !menuVisable ? "none" : "flex"}}>

					<NavLink to={`${adminRoot}home`} exact activeClassName={classes.activeLink}>{text.home}</NavLink>

					<NavLink to={`${adminRoot}home/recipes`} activeClassName={classes.activeLink} style={{marginInlineEnd: 32}}>{text.recipes}</NavLink>


					<Button theme={theme} style={{margin: 0}} onClick={() => {
						let jwt = localStorage.getItem('token');

						if (jwt && jwt.length > 0) {
							history.push("/submit-recipe");
						} else {
							history.push("/register")
						}
					}}>
						{text.submitRecipe}
					</Button>

				</div>


				<div style={{display: "flex", marginInlineStart: 12}}>

					<div className={classes.userSection} onClick={() => {
						if (userInfo) {
							setUserMenuVisible(!userMenuVisible);
						} else {
							history.push("/login");
						}
					}}>

						{userInfo ? <img src={userInfo.profile_picture}/> : <i className={"simple-icon-user"}/>}

						<p>{userInfo ? userInfo.username : text.yourAccount}</p>

						<div className={classes.userMenu} ref={clickOutSideRef} style={{display: userMenuVisible ? "flex" : "none"}}>

							<span onClick={() => history.push("/profile")}>
								{text.profile}
							</span>

							<span onClick={logout}>
								{text.logout}
							</span>

						</div>

					</div>


					<div style={{display: "flex", alignItems: "center"}}>
						<i className="simple-icon-menu" style={{
							display: width < 780 ? "block" : "none",
							paddingInlineStart: 12,
						}} onClick={() => setMenuVisable(!menuVisable)}/>
					</div>

				</div>

			</div>

		</div>
	);
}

export default Header;
