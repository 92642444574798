import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {ThemeContext} from "../context/ThemeContext";
import {LangContext} from "../context/LangContext";
import {NavLink} from "react-router-dom";

/**
 *
 * @param iconsArray array of icons each icon has {className: string, hoverColor: string, link: string}
 * @returns {JSX.Element}
 * @constructor
 */
export function SocialIcons({iconsArray}) {

	const [iconsState, setIconState] = useState([]);

	const {theme, toggleTheme} = useContext(ThemeContext);
	const {lang, toggleLang} = useContext(LangContext);

	const useStyle = makeStyles({
		mainContainer: {
			display: "flex",
			marginTop: 22,

			"& i": {
				display: "inline-block",
				borderRadius: 60,
				boxShadow: "0px 0px 2px #888",
				padding: "0.5em 0.6em",
				cursor: "pointer",
				marginInlineEnd: 12,
				color: theme => theme.fontColor,
			},
			"& > a:hover":{
				color: "unset !important",
			},
			"& i:hover": {
				color: theme => theme.name === "light" ? theme.theme6 : "unset",
			},
		},
	});

	useEffect(() => {
		let boolArray = [];

		boolArray.fill(false, 0, iconsArray.length);

		setIconState(boolArray);
	}, []);

	const classes = useStyle({...theme, lang: lang});

	return <div className={classes.mainContainer}>
		{iconsArray.map((icon, index) => {
			return (<a href={icon.link}>

				<i
					onMouseOver={() => {
						let currentIconState = [...iconsState];

						currentIconState[index] = true;

						setIconState(currentIconState);
					}}

					onMouseOut={() => {
						let currentIconState = [...iconsState];

						currentIconState[index] = false;

						setIconState(currentIconState);
					}}

					className={icon.className} style={{background: iconsState[index] ? icon.hoverColor : "unset"}}/>

			</a>
			)
		})}
	</div>;
}
