import Lottie from "react-lottie-player";
import starsAnimation from "../assets/lottie/stars.json";
import {useState} from "react";

export const Rating = (props) => {

	const [stars, setStars] = useState(props.stars)

	return (
		<div title={stars}>
			{/*the number 24 is chosen because the maximum stars is 5 and the animation maximum is 120*/}
			{/*following the equation 120 / 5 * stars = number of frames*/}
			<Lottie animationData={starsAnimation} play={false} goTo={stars * 24} style={{width: 100, height: 20}}/>
		</div>
	)
}
