import React, {useContext} from 'react';
import {makeStyles} from "@material-ui/styles";
import {ThemeContext} from "../context/ThemeContext";
import {LangContext} from "../context/LangContext";
import { motion } from "framer-motion";


function Category({selected, name_en, name_ar, amount, image, onClick, simple}) {

	const {theme, toggleTheme} = useContext(ThemeContext);
	const {lang, toggleLang} = useContext(LangContext);

	const useStyle = makeStyles({
		mainContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-evenly",
			background: theme => theme.backgroundColor,
			width: "fit-content",
			padding: "20px 38px",
			boxShadow: theme => theme.name === "light" && "0 10px 20px 0 rgba(0,0,0,.1)!important",
			cursor: "pointer",
			position: "relative",
			borderRadius: 5,
			transition: "all 200ms",
			minWidth: 90,

			"&::before":{
				content: theme.name === "light" ? "" : "''",
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				background: theme => [theme.elevation2, "!important"],
				borderRadius: 5,
			},

			"&:hover":{
				background: theme => theme.theme3,
				color: theme => theme.theme6,

				"& > span:nth-of-type(2)":{
					color: theme => theme.theme6,
				},

				"& img":{
					filter: 'brightness(0);invert(1)'
				},
			},


			"& > span:nth-of-type(1)":{
				fontWeight: "600"
			},

			"& > span:nth-of-type(2)":{
				color: "grey",
			},

			"& img":{
				width: 80,
				height: 90,
			},

		},

		selected: {
			transform: ["translateY(-15px)", "!important"],

			"&::before":{
				background: theme => [theme.elevation8, "!important"],
			},

		}

	})

	const classes = useStyle(theme)

	const categoriesFramerVariants = {
		visible: {y: 0, opacity: 1},
		hidden: {y: -70, opacity: 0,}
	}

	return (
		<motion.div onClick={onClick} className={`${classes.mainContainer} ${selected ? classes.selected : ""}`} variants={categoriesFramerVariants} whileHover={{scale: 1.1}}  >
			<img src={image} alt={name_en + " icon"} style={{objectFit: "contain"}}/>
			<span>{lang === "ar" ? name_ar : name_en}</span>
			<span>({amount})</span>
		</motion.div>
	);
}

export default Category;
