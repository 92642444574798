import React, {useContext, useEffect, useState} from 'react';
import {ThemeContext} from "../context/ThemeContext";
import {LangContext} from "../context/LangContext";
import {makeStyles} from "@material-ui/styles";
import {NavLink, useHistory} from "react-router-dom";
import {adminRoot, servicePath} from "../constants/Values";
import * as PropTypes from "prop-types";
import {SocialIcons} from "./SocialIcons";

function Footer(props) {
	const {theme, toggleTheme} = useContext(ThemeContext);
	const {lang, toggleLang} = useContext(LangContext);

	const socialIcons = [
		{"className": "simple-icon-social-facebook", "hoverColor": "#4c1ff7", "link": "https://example.com"},
		{"className": "simple-icon-social-twitter", "hoverColor": "#649ce1", "link": "https://example.com"},
		{"className": "simple-icon-social-instagram", "hoverColor": "#de008e", "link": "https://example.com"},
		{"className": "simple-icon-social-linkedin", "hoverColor": "#2a4b84", "link": "https://example.com"},
	];

	const history = useHistory();

	const [categories, setCategories] = useState([]);

	function getCategories() {
		fetch(`${servicePath}get-categories`)
			.then(response => response.json())
			.then(categories => setCategories(categories));
	}

	useEffect(() => {
		getCategories()
	}, [])


	const useStyle = makeStyles({
		mainContainer: {
			marginTop: 50,
			width: "100%",
			background: theme => theme.elevation4,
			boxShadow: theme => theme.boxShadow,
		},
		innerContainer: {
			height: "100%",
			display: "flex",
			flexWrap: "wrap",
			gap: 30,
			justifyContent: "space-between",
			paddingTop: 50,
		},

		linkList: {
			listStyleType: "none",
			padding: 0,
			"& a": {
				color: theme => theme.fontColor,
			},
			"& > li": {
				marginBottom: "8px !important",
			},
			"& li:not(:last-child)": {
				marginBottom: 0,
			},
		},

		links: {
			display: "flex",
			flexDirection: "column",
			marginInlineEnd: 70,
			"& > span": {
				fontWeight: 600,
			},

			"& > a:not(:last-child)": {
				marginInlineEnd: 12,
			},
		},

		logo: {
			height: 60,
		},

		copyRightContainer: {
			height: 50,
			borderTop: "1px solid rgba(0, 0, 0, 0.2)",
			display: "flex",
			justifyContent: "start",
			alignItems: "center",
		},

	});

	const classes = useStyle({...theme, lang: lang});

	const text =
		lang === "ar" ?
			{
				home: "الرئيسية",
				recipes: "الوصفات",
				pages: "الصفحات",
				categories: "الفئات",
				footerP1: "نحن نقدم وصفات جديدة مع لمسة يومية.",
				footerP2: "أقوم أيضًا بنشر مشاركات مدونة حول الأفكار الممتعة التي يمكن القيام بها في المطبخ",

			}
			:
			{
				home: "Home",
				recipes: "Recipes",
				pages: "Pages",
				categories: "Categories",
				footerP1: "we provide new recipes with a twist on daily basis.",
				footerP2: "I also post blog posts about fun ideas to do in the kitchen",

			};

	return (
		<div className={classes.mainContainer}>
			<div className={classes.innerContainer + " container"}>

				<div onClick={() => history.push("/home")} style={{cursor: "pointer"}}>
					<img src={theme.name === "light" ? "/svgs/logo-light.svg" : "/svgs/logo-dark.svg"}
						 alt={"company logo"} className={classes.logo}/>

					<p> {text.footerP1}<br/>{text.footerP2}</p>
					<SocialIcons iconsArray={socialIcons}/>
				</div>


				<div className={classes.links}>
					<span>{text.categories}</span>
					<ul className={classes.linkList}>
						{categories.map((category) => {
							return (
								<li>
									<span
										onClick={() => {
											props.filtersReducer({type: 'setCategory', payload: category});
											history.push("/home/recipes");
										}}
										style={{"cursor": "pointer"}}
									>{lang === "ar" ? category.name_ar : category.name_en} </span>
								</li>
							);
						})}
					</ul>
				</div>

				<div className={classes.links}>
					<span>{text.pages}</span>

					<ul className={classes.linkList}>
						<li>
							<NavLink to={adminRoot + "home"}>{text.home}</NavLink>
						</li>
						<li>
							<NavLink to={`${adminRoot}home/recipes`}>{text.recipes}</NavLink>
						</li>
					</ul>

				</div>
			</div>

			<div className={classes.copyRightContainer}>
				<div className={"container"}>
					<span>Copyright 2021 - Tapkha.com</span>
				</div>
			</div>
		</div>
	);
}

export default Footer;
