import React, {useContext, useEffect} from 'react';
import {makeStyles} from "@material-ui/styles";
import {ThemeContext} from "../context/ThemeContext";
import {adminRoot, difficultyAR, difficultyEN, servicePath} from "../constants/Values";
import {LangContext} from "../context/LangContext";
import {Rating} from "./Rating";
import {motion} from "framer-motion";
import {NavLink, useHistory} from "react-router-dom";
import {Button} from "../StyledComponents";
import {NotificationManager} from "react-notifications";

function ListedRecipe(props) {

	const {theme, toggleTheme} = useContext(ThemeContext);
	const {lang, toggleLang} = useContext(LangContext);

	const history = useHistory();

	const useStyle = makeStyles({
		mainContainer: {
			display: "flex",
			flexDirection: "column",
			textAlign: "start",
			maxWidth: "100%",
		},

		textContainer: {
			padding: "15px 30px 15px",

			"& > h1": {
				marginTop: 0,
				marginBottom: 0,
			},

			"& > p": {
				display: "box",
				maxWidth: "100%",
				lineClamp: 3,
				boxOrient: "vertical",
				overflow: "hidden",
			},
		},

		categories: {
			display: "flex",
			justifyContent: "center",
			gap: 12,
			color: theme => theme.theme3,

			"& > span": {
				cursor: "pointer",
			},
		},

		lastRow: {
			display: "flex",
			justifyContent: "space-between",
		},

		imageContainer: {
			borderRadius: 4,
			cursor: "pointer",
			width: "100%",
			overflow: "hidden",
			display: "flex",

			"& img": {
				width: "100%",
				objectFit: "cover",
			},
		},

		missingContainer: {
			height: 30,
			width: "100%",
			display: "flex",
			color: theme => theme.errorColor,
			background: theme => theme.elevation8,
			borderRadius: 12,
			paddingLeft: 12,
			paddingRight: 12,
			marginTop: 4,

		},
	});

	const classes = useStyle(theme);


	const text =
		lang === "ar" ?
			{
				difficulty: "الصعوبة",
				missing: "بدون",
				delete: "حذف",
				update: "تعديل"
			}
			:
			{
				difficulty: "Difficulty",
				missing: "Missing",
				delete: "Delete",
				update: "Update"
			};


	function deleteRequest(){
		let jwt = localStorage.getItem('token');

		if (jwt) {

			const data = new FormData();
			//authentication
			data.append("jwt", jwt);
			data.append("recipeId", props.id);

			fetch(`${servicePath}delete-recipe`, {
				method: 'POST',
				body: data,
				headers:
					{
						"Accept": "application/json",
					},
			}).then(function (res) {
				return res.json();
			}).then(function (data) {
				if (data == 1){
					NotificationManager.success("", 'Deleted', 5000, null, null, '');
					window.location.reload();
				} else {
					NotificationManager.success("", 'user authentication failed', 5000, null, null, '');
				}
			}).catch(function (error) {
				NotificationManager.error("حدث خطاء في الإتصال تأكد من اتصالك بالأنترنيت", 'خظاء في الإتصال', 5000, null, null, '');
			});

		}
	}


	return (
		<motion.div layout initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} className={classes.mainContainer}>
			<div className={classes.imageContainer}>
				<NavLink to={adminRoot + "recipe/" + props.id} target="_blank" rel="noopener noreferrer" >
					<motion.img src={props.image} alt={"Recipe Image"} whileHover={{scale: 1.15, rotate: "5deg"}}/>
				</NavLink>
			</div>

			<div className={classes.textContainer}>

				{props.categories ? <div className={classes.categories}>
					{props.categories.map((category) => {
						return <span onClick={() => {
							props.filterReducer({type: 'setCategory', payload: category});
							history.push("/home/recipes");
						}
						}>
							{category[lang === "ar" ? "name_ar" : "name_en"]}
					</span>;
					})}
				</div> : <></>}


				<h1>
					{props[lang === "ar" ? "name_ar" : "name_en"]}
				</h1>

				<Rating stars={props.rating}/>

				<p>
					{props[lang === "ar" ? "description_ar" : "description_en"]}
				</p>

				<div className={classes.lastRow}>
					<div>
						{text.difficulty}: {lang === "ar" ? difficultyAR[props.difficulty] : difficultyEN[props.difficulty]}
					</div>
					<div>
						<i className={"iconsminds-stopwatch"}></i> {props.prepTime} {lang === "ar" ? "دقيقه" : "min"}
					</div>
				</div>

				{props.searchedIngredients &&
					props.searchedIngredients.length > 0 &&
					props.searchedIngredients.filter((curr) => {
						return !(props.ingredients.reduce((acc, curr) => {
							acc.push(curr.id);
							return acc;
						}, []).includes(curr.id));
					}).length > 0 &&
					<div className={classes.missingContainer}>
						<span>
							{text.missing}:
							<div style={{display: "inline-flex", gap: 4, marginLeft: 2}}>
								{props.searchedIngredients.filter((curr) => {
									return !(props.ingredients.reduce((acc, curr) => {
										acc.push(curr.id);
										return acc;
									}, []).includes(curr.id));
								}).map((ingredient, index, arr) => {
									return <span style={{display: "flex", alignItems: "center"}}>
											{lang === "ar" ? ingredient.name_ar : ingredient.name_en}{arr.length > index + 1 && ","}
										</span>;
								})
								}
							</div>

						</span>
					</div>
				}

				{
					props.action
					&&
					<div style={{display: "flex", justifyContent: "end"}}>
						<Button theme={theme} onClick={() => {
							history.push("/submit-recipe", {updating: true, recipe: JSON.parse(JSON.stringify(props))})
						}}>{text.update}</Button>
						<Button theme={theme} style={{background: theme.errorColor}} onClick={deleteRequest}>{text.delete}</Button>
					</div>
				}

			</div>


		</motion.div>
	);
}

export default ListedRecipe;
